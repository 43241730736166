<template>
  <el-row :gutter="20">
    <el-card class="box-card">
         

      <div class="demo-basic--circle">
        <div class="block"><el-avatar :size="150" :src="avatar"></el-avatar></div>
      </div>
      <h1 class="title">短视频在线去水印</h1>
      <h1 class="subtitle">Embrace the future</h1>

       <div class="card center-text-mobile">
        <p class="site-announcement">提示：去水印请点击放大镜🔍</p>
        <div class="scrolling-text">
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
      </div>
      <!-- 将 input-container 和图片移动到这里 -->
        <img class="card-image" src="https://tc.qianying888.com/app/hide.php?key=cElET1Q1VnB6cjdXZEtoSWtIMThDWGNDcC9pSkVRYUM=" alt="Card Image">
        <div class="input-container">
          <input type="text" name="text" class="input" placeholder="请粘贴分享链接" v-model="input">
          <svg xmlns="http://www.w3.org/2000/svg" fill="" viewBox="0 0 24 24" class="search-icon" @click="onSubmit">
            <g stroke-width="0" id="SVGRepo_bgCarrier"></g>
            <g stroke-linejoin="round" stroke-linecap="round" id="SVGRepo_tracerCarrier"></g>
            <g id="SVGRepo_iconCarrier">
              <rect fill="white" height="24" width="24"></rect>
              <path fill="" d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM9 11.5C9 10.1193 10.1193 9 11.5 9C12.8807 9 14 10.1193 14 11.5C14 12.8807 12.8807 14 11.5 14C10.1193 14 9 12.8807 9 11.5ZM11.5 7C9.01472 7 7 9.01472 7 11.5C7 13.9853 9.01472 16 11.5 16C12.3805 16 13.202 15.7471 13.8957 15.31L15.2929 16.7071C15.6834 17.0976 16.3166 17.0976 16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929L15.31 13.8957C15.7471 13.202 16 12.3805 16 11.5C16 9.01472 13.9853 7 11.5 7Z" clip-rule="evenodd" fill-rule="evenodd"></path>
            </g>
          </svg>
        </div>
        
        
      
         
      <div class="additional-text">
        <div class="ggtitle">
        <p>🏅优点</p>
        
        </div>
        <div class="tags-container">
          <el-tag class="custom-tag">方便快捷</el-tag>
          <el-tag class="custom-tag">一键解析</el-tag>
          <el-tag class="custom-tag">去除水印</el-tag>
          <el-tag class="custom-tag">UI设计</el-tag>
        </div>
        
        
      <div class="container">
        <div class="info-card">
          <p class="site-announcement">目前支持</p>
          <div class="support-items-container">
            <div class="support-item">
              <img src="https://tc.qianying888.com/app/hide.php?key=bDFWcG5kZDgzcHFONEdtdXkvaVVtZysxUmJkdHAxVVU=" class="icon" alt="皮皮虾">
            </div>
            <div class="support-item">
              <img src="https://tc.qianying888.com/app/hide.php?key=OFJMRUI5VEMvb2VOWExlbjlJRGhQZysxUmJkdHAxVVU=" class="icon" alt="抖音">
            </div>
            <div class="support-item">
              <img src="https://tc.qianying888.com/app/hide.php?key=em9FY3VJMVpTZUtleWlwWW05bXNBdysxUmJkdHAxVVU=" class="icon" alt="微视">
            </div>
            <div class="support-item">
              <img src="https://tc.qianying888.com/app/hide.php?key=NkNsVTg5c3dFQWRVUDBmZXMzeG4vQSsxUmJkdHAxVVU=" class="icon" alt="快手">
            </div>
            <div class="support-item">
              <img src="https://tc.qianying888.com/app/hide.php?key=THNIWWxhdmc1NU9WNnZVMis4QmkvZysxUmJkdHAxVVU=" class="icon" alt="6间房">
            </div>
            <div class="support-item">
              <img src="https://tc.qianying888.com/app/hide.php?key=dkk4dXFLUS9tWm9vblhlK1ZSTFNEUSsxUmJkdHAxVVU=" class="icon" alt="哔哩哔哩">
            </div>
            <div class="support-item">
              <img src="https://tc.qianying888.com/app/hide.php?key=LzkyTVZkNXdrQ0hva3lUZWZlRlFqQSsxUmJkdHAxVVU=" class="icon" alt="微博">
            </div>
            <div class="support-item">
              <img src="https://tc.qianying888.com/app/hide.php?key=ejBGWEJZNTkxWnNySzhpWFUwOXBSQSsxUmJkdHAxVVU=" class="icon" alt="绿洲">
            </div>
            <div class="support-item">
              <img src="https://tc.qianying888.com/app/hide.php?key=SDdNYVpYNUlsbnMzaU9GQ3llY0MvUSsxUmJkdHAxVVU=" class="icon" alt="度小视">
            </div>
            <div class="support-item">
              <img src="https://tc.qianying888.com/app/hide.php?key=WmxJQ2NpcHVqQnVtWWMzUU1iMUFzd3RoMVo3U1RMNUc=" class="icon" alt="陌陌">
            </div>
            <div class="support-item">
              <img src="https://tc.qianying888.com/app/hide.php?key=R083VmtORnZGeitqNTJLclZ0REg5UXRoMVo3U1RMNUc=" class="icon" alt="皮皮搞笑">
            </div>
            <div class="support-item">
              <img src="https://tc.qianying888.com/app/hide.php?key=OS9Dai9SaFVJNE9wbGt3QmFpRzdiUXRoMVo3U1RMNUc=" class="icon" alt="全民K歌">
            </div>
            <div class="support-item">
              <img src="https://tc.qianying888.com/app/hide.php?key=SVhIZ1I2aTBoTTZQQVlremJRTHErUXRoMVo3U1RMNUc=" class="icon" alt="逗拍">
            </div>
            <div class="support-item">
              <img src="https://tc.qianying888.com/app/hide.php?key=ckVWa29uUy84RVNJRnY1RmNXWWlTZ3RoMVo3U1RMNUc=" class="icon" alt="虎牙">
            </div>
            <div class="support-item">
              <img src="https://tc.qianying888.com/app/hide.php?key=NGJqVnErQ2FsaTJTbHNsc1NpMzlUUXRoMVo3U1RMNUc=" class="icon" alt="新片场">
            </div>
            <div class="support-item">
              <img src="https://tc.qianying888.com/app/hide.php?key=TXlDOVVHTGk0Y2Q5TG82bGN0dVZud3RoMVo3U1RMNUc=" class="icon" alt="Acfun">
            </div>
            <div class="support-item">
              <img src="https://tc.qianying888.com/app/hide.php?key=Myt1UEtndGkrb0tnaEx5WGlueG9Md3RoMVo3U1RMNUc=" class="icon" alt="美拍">
            </div>
            <div class="support-item">
              <img src="https://tc.qianying888.com/app/hide.php?key=N3g2aWgrQ1dXYWFaV3NGVjRnbVRyUXRoMVo3U1RMNUc=" class="icon" alt="西瓜视频">
            </div>
            <div class="support-item">
              <img src="https://tc.qianying888.com/app/hide.php?key=aHFIRzdQOUJ6a1c1N29zU0xaOEFqUXRoMVo3U1RMNUc=" class="icon" alt="火山小视频">
            </div>
            <div class="support-item">
              <img src="https://tc.qianying888.com/app/hide.php?key=S0JpaGpPU3UvNjNuTU1Kd2syS2FHQXRoMVo3U1RMNUc=" class="icon" alt="网易云Mlog">
            </div>
            
            </div>
            </div>
            </div>
          <div class="right-cards">
    <a href="https://mofa.qianying888.com" target="_blank" class="announcement-link">
      <div class="announcement-card">
        <div class="announcement-title"></div>
        <p class="move-up"></p>
        <p class="move-left"></p>
      </div>
    </a>
    <a href="https://svip.qianying888.com" target="_blank" class="tip-link">
      <div class="tip-card">
        <div class="tip-title"></div>
        <p class="move-up"></p>
        <p class="move-left"></p>
      </div>
    </a>
  </div>
</div>
      
      


          <hr>
           <div class="main" v-loading="loading">
        <div class="grid-content">
          

        
        </div>
      </div>
    </el-card>

    <!-- 弹框显示解析结果 -->
    <el-dialog :visible.sync="dialogVisible" custom-class="custom-dialog" title="解析结果">
      <div v-if="seen">
        <div class="dialog-icon-container">
          <img src="https://tc.qianying888.com/app/hide.php?key=VVNacEU3WGRhOEJDdzFaL01OUk9xV2g5RmU5SlF4UG8=" class="dialog-icon" alt="解析结果图标">
          <h4>{{info.title}}</h4>
        </div>
        <a :href="info.cover" target="_blank"><el-button class="download-button" plain>下载封面</el-button></a>
        <a :href="info.url" target="_blank"><el-button class="download-button" plain>下载视频</el-button></a>
        
      </div>
      <div v-if="iseen">
        <div class="waterfall">
          <div class="item" v-for="url in images_url" :key="url">
            <el-image :src="url" :preview-src-list="images_url"></el-image>
          </div>
        </div>
      </div>
    </el-dialog>
  </el-row>
</template>

<script>
import confetti from "canvas-confetti";

export default {
  data() {
    return {
      info: {},
      input: '',
      select: '',
      music: false,
      seen: false,
      iseen: false,
      loading: false,
      dialogVisible: false, // 控制弹框显示
      avatar: "https://tc.qianying888.com/app/hide.php?key=WnFVUm5iODZDcTlRMy84emFxYWVVZ0J2QmRyWjNFd1k=",
      images_url: {}
    }
  },
  methods: {
    onSubmit() {
      this.seen = false
      this.iseen = false
      this.loading = true
      const url = /(https?|http):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/.exec(this.input)
      if(this.select == '1' || this.select == ''){
        if (!url) {
          this.loading = false;
          this.$notify.error({
            title: '解析失败',
            message: '你倒是输入链接啊！'
          });
          return;
        }

        this.axios.get('https://tenapi.cn/v2/video?url=' + encodeURIComponent(url[0])).then((res) => {
          if(res.data.code == 200){
            if(res.data.data.music != null){
              this.music = true
            }
            this.seen = true
            this.info = res.data.data
            this.loading = false
            this.dialogVisible = true // 显示弹框
            this.$notify.success({
              title: '解析成功',
              message: '请及时下载音视频'
            });
            this.runConfetti(); // 触发礼花效果
          } else {
            this.seen = false
            this.loading = false
            this.$notify.error({
              title: '解析失败',
              message: '视频不存在或接口失效'
            });
          }
        })
      } else {
        this.seen = false
        this.axios.get('https://tenapi.cn/v2/images?url=' + encodeURIComponent(url[0])).then((res) => {
          if(res.data.code == 200){
            this.iseen = true
            this.loading = false
            this.images_url = res.data.data.images
            this.dialogVisible = true // 显示弹框
            this.$notify.success({
              title: '解析成功',
              message: '图集暂不支持批量下载，请长按下载或右键另存为'
            });
            this.runConfetti(); // 触发礼花效果
          } else {
            this.iseen = false
            this.loading = false
            this.$notify.error({
              title: '解析失败',
              message: '图集不存在或接口失效'
            });
          }
          console.log(this.images_url);
        })
      }
    },
    runConfetti() {
      confetti({
        particleCount: 100, //粒子数量
        spread: 70,         //粒子速度
        origin: {           //初始位置
          x: 0.5,
          y: 0.5,
        },
        zIndex: 9999       // 设置较高的z-index，确保礼花效果在最上层
      });
    }
  }
}
</script>


<style>
  
  ::selection {
    background: rgba(0,149,255,.1);
  }
  

  body:before{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .3;
    z-index: -1;
    content: "";
    position: fixed;
    background-image: linear-gradient( 135deg, #096dd9 10%, #ff4d4f 100%);

  }

  .grid-content {
    margin-top: 1em;
    border-radius: 4px;
    min-height: 50px;
  }

  .el-select .el-input {
    width: 80px;
  }
  .el-avatar {
    background: transparent !important;
   
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    overflow: hidden;
    color: #fff;

    width: 13px;
    height: 17px;
    line-height: 22px;
    position: fixed;
    top: -13px;
    right: -15px;
    transform: scale(0.6);

}
    .el-card::before, .el-card::after {
    content: '';
    position: absolute;
    top: -51px;
    right: -71px;
    width: 300px;
    height: 300px;
    background-color: #F8E192;
    border-radius: 50%;
    z-index: -1;
}
    .el-card::after {
        background: radial-gradient(circle, rgba(47, 203, 252, 0) 45%, rgba(47, 203, 252, 0.3) 37%);
            top: -182px;
    width: 500px;
    height: 500px;
    left: -198px;
}
.el-input-group__append {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 34px !important;
    border-bottom-right-radius: 34px !important;
    border-bottom-left-radius: 4px !important;
    border: 1px dashed #09afe899 !important;
}


.el-button {
    border-top-left-radius: 34px !important;
    border-top-right-radius: 34px !important;
    border-bottom-right-radius: 34px !important;
    border-bottom-left-radius: 34px !important;
    border-style: dotted !important;
    border-color: #09afe899 ;
    cursor: default !important;
    border: 1px dashed #09afe899 !important;
}

.el-card {
    transform: translateY(53px) !important;
}
.title {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: -webkit-isolate;
    unicode-bidi: -moz-isolate;
    unicode-bidi: isolate;
    font-size: 24px;
    margin: 0;
    color: #191919;
    top: 14px;
    position: relative;
    left: -276px;
}
.subtitle {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: -webkit-isolate;
    unicode-bidi: -moz-isolate;
    unicode-bidi: isolate;
    font-size: 16px;
    margin: 0;
    color: #191919;
    top: 26px;
    position: relative;
    left: -289px;
}
.el-dialog {
    position: relative;
    margin: 0 auto 50px;
    background: #FFF;
    border-radius: 24px!important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
    box-sizing: border-box;
    width: 50%;
}
.custom-dialog .el-dialog__header {
    background-color: #fff;
    border-bottom: 1px solid #ebeef5;
    border-radius: 24px 24px 0 0;
  }

.custom-dialog .el-dialog__body {
    border-radius: 0 0 10px 10px;
  }

.custom-dialog .el-dialog__title {
    color: #6595e8;
    text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 2px 2px 0 #aaa;
    font-weight: bold;
    font-size: 18px;
  }
  
.el-button.is-plain:focus, .el-button.is-plain:hover {
    background: #fff;
    border: 1px dashed #09afe899 !important;
    color: #409EFF;
}

.dialog-icon {
  width: 40px; /* 根据需要调整图标的宽度 */
  height: 40px; /* 根据需要调整图标的高度 */
  margin-right: 8px; /* 根据需要调整图标和文字之间的间距 */
  vertical-align: middle;
}


.download-button {
  margin-right: 26px!important; /* 调整按钮之间的间距 */
}
/* From Uiverse.io by Yaya12085 */ 
    .input-container {
        position: absolute;
        display: flex;
        align-items: center;
        top: 265px;
        z-index: 2;
        left: 39px;
        width: 685px;
    }
       .card-image {
    width: 238px;
    height: auto;
    border-radius: 19px 19px 0 0;
    position: relative;
    top: -153px;
    left: 296px;
}
    .ggtitle {
    text-align: center;
    font-size: 17px;
    transform: translateY(28px);
    color: #555;
    margin-left: 2px;
    font-weight: bold;
    position: fixed;
    top: -167px;
    width: auto;
    height: 3px;
    left: 328px;
}
    .el-tag {
        background-color: transparent !important;
        height: 32px !important;
        line-height: 30px !important;
        font-size: 12px !important;
        border-width: 1px !important;
        border-style: solid !important;
        box-sizing: border-box !important;
        white-space: nowrap !important;
        border: 1px dashed #09afe899 !important;
    }
        .tags-container {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    top: -81px;
    left: 329px;
    position: fixed;
}
    .custom-tag {
        border-radius: 20px !important;
        padding: 1px 10px !important;
        color: #5b6bff !important;
    }
        .info-card {
    position: relative;
    width: 336px;
    padding: 14px;
    border: 0 solid #ccc;
    border-radius: 20px;
    background-color: #D8C9AE;
    /* background-size: 150px 244px; */
    /* background-position: center; */
    /* background-repeat: no-repeat; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
       top: -19px;
    left: 331px;
    height: 177px;
}
.announcement-card {
    position: absolute;
    padding: 13px;
    border: 0px solid #ccc;
    border-radius: 20px;
    background-image: url(https://tc.qianying888.com/app/hide.php?key=V2tNTmJZYjk0SDFoMGdrWUtraDZKcENMNDE4cVFKZHc=);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    top: -19px;
    left: 722px;
    right: 7px;
    color: white !important;
    height: 69px;
    width: auto;
}
    .tip-card {
    padding: 13px;
    border: 0px solid #ccc;
    border-radius: 20px;
    background-image: url(https://tc.qianying888.com/app/hide.php?key=RHE5K3c2cm55QmlHNTA5RlZ5THNIWkNMNDE4cVFKZHc=);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    top: 93px;
    left: 722px;
    color: white !important;
    position: absolute;
    width: auto;
    height: 66px;
    right: 7px;
}
    .el-loading-spinner {
        top: -330% !important;
        margin-top: -345px !important;
        width: 100% !important;
        text-align: center !important;
        position: absolute !important;
            left: -240px;
    }
        .additional-text {
        text-align: center;
        font-size: 14px;
        transform: translateY(-228px);
        color: #555;
        margin-left: -322px;
        font-weight: bold;
        position: relative;
        top: 160px;
    }

.input {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  border: none;
  outline: none;
  padding: 18px 19px;
  background-color: transparent;
  cursor: pointer;
  transition: all .5s ease-in-out;
}

.input::placeholder {
  color: transparent;
}

.input:focus::placeholder {
  color: rgb(131, 128, 128);
}

.input:focus,.input:not(:placeholder-shown) {
  background-color: #fff;
  border: 1px solid rgb(91, 107, 255);
  width: 290px;
  cursor: text; /* 显示文本输入光标 */
  padding: 18px 16px 18px 45px;
}

.search-icon {
  position: absolute;
  left: 0;
  height: 45px;
  width: 45px;
  background-color: #fff;
  border-radius: 99px;
  z-index: -1;
  fill: rgb(91, 107, 255);
  border: 1px solid rgb(91, 107, 255);
}

.input:focus + .search-icon,.input:not(:placeholder-shown) + .search-icon {
  z-index: 0;
  background-color: transparent;
  border: none;
}
 .lihua {
    width: 25px;
    position: absolute;
    left: 95%;
    top: 88%;
    transform: translateX(-50%);
    z-index: 9999; 
  }


.notification {
  display: flex;
  align-items: center;
  justify-content: center;
  width: .8rem;
  height: .8rem;
  position: absolute;
  top: 10px;
  left: 30%;
  font-size: 10px;
  margin-left: 0.75rem;
  border-radius: 50%;
  margin: 0px;
  background-color: #e6eef9;
  transition: 0.15s ease-in;
}
.el-notification {
    border-radius: 50px!important;

}
.site-announcement {

        font-size: 20px; /* 调整字体大小 */

        font-weight: bold;

        color: #fff; /* 文字颜色 */


    }

.card {
    border: 0px solid #ccc;
    padding: 26px;
    margin: 12px;
    border-radius: 19px;
    overflow: hidden;
    top: 50px;
    position: relative;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    background-color: #26202C;
    margin: 17px 6px;
}
         

        .card p {

            margin: 5px 0; /* 缩短段落间的间隔 */
            font-size: 0.9em;
            font-weight: bold;

        }

        .support-items-container {

            display: flex;

            flex-wrap: wrap;

            justify-content: center;
            top: 8px;
    position: relative;

        }

        .support-item {

            width: 17%;

            margin: 5px 0;

            display: flex;

            justify-content: center;

            align-items: center;
            top: -20px;
    position: relative;

        }

        .icon {

            width: 20px;

            height: 20px;

        
        
        
        
        
       
  }
  


        
        

  @media screen and (max-width: 700px){
    .box-card {
      margin-top: 1em!important;
      margin-bottom: 1em!important;
    }
  }
  .download h3{
      margin-top: 2em;
    }

  .download button{
    margin-right: 0.5em;
    margin-left: 0.5em;
  }

  .waterfall {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
    -moz-column-gap: 1em;
    -webkit-column-gap: 1em;
    column-gap: 1em;
  }

  .item {
    padding: 5px;
    break-inside: avoid;
  }

  .item img {
    width: 100%;
    margin-bottom: 10px;
  }
  .typo {
    text-align: left;
  }
  .typo a {
    color: #2c3e50;
    text-decoration:none;
  }

  hr{
    height: 10px;
    margin-bottom: .8em;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.12);
  }


            .card.center-text-mobile {

                text-align: center;

            }
          
            /* 缩短手机端文字上下间隔 */

            .card.center-text-mobile p {

                margin: 3px 0; /* 缩短段落间的间隔 */
                
                

            }
            /* 适应手机端 */

@media (max-width: 768px) {
    .site-announcement {
        font-size: 14px; /* 调整字体大小 */
        font-weight: bold;
        color: #fff; /* 文字颜色 */
        text-shadow: none; /* 禁用 text-shadow */
    }

        .title {
        display: block;
        font-size: 20px;
        margin: 0;
        color: #409eff;
        top: 22px;
        
        left: 33px;
        position: fixed;
    }

        .subtitle {
        display: block;
        font-size: 16px;
        margin: 0;
        color: #409eff;
        top: 49px;
        
        left: 37px;
        position: fixed;
    }

    .main {
        position: relative;
        height: -0.5vmax;
        justify-content: center;
        align-items: center;
    }

    .dog {
        position: relative;
        width: 25vmax;
        height: 2vmax;
        transform: scale(0.9);
        left: 58px;
        top: 0px;
    }

    .support-item {
        width: 20%;
    }

    .el-card {
        position: fixed; /* 固定位置 */
        top: -70px; /* 从顶部开始 */
        left: -1px; /* 从左边开始 */
        width: 100vw; /* 占据整个视口宽度 */
        height: 100vh; /* 占据整个视口高度 */
        border-radius: 0; /* 移除圆角 */
        border: none; /* 移除边框 */
        overflow-y: auto; /* 允许垂直滚动 */
    }

    .el-card::before,
    .el-card::after {
        content: '';
        position: absolute;
        top: -46px;
        right: -60px;
        width: 180px;
        height: 180px;
        background-color: #F8E192;
        border-radius: 50%;
        z-index: -1;
    }

    .el-card::before {
        background-color: #F8E192; /* 设置颜色1 */
    }

    .el-card::after {
        background: radial-gradient(circle, rgba(47, 203, 252, 0) 45%, rgba(47, 203, 252, 0.3) 37%);
        top: -185px;
        
        width: 400px;
        height: 400px;
        left: -198px;
    }

    .el-dialog {
        position: relative;
        margin: 0 auto 50px;
        background: #FFF;
        border-radius: 24px!important;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
        box-sizing: border-box;
        width: 89%!important;
        top: 60px;
        z-index: 100000!important; /* 确保弹出框在最上层 */
    }

    .el-dialog__body {
        padding: 14px 9px!important;
        color: #606266;
        font-size: 14px;
        word-break: break-all;
        z-index: 100000!important; /* 确保弹出框在最上层 */
    }

    .el-button:focus, .el-button:active {
        outline: none; /* 去除轮廓 */
        box-shadow: none; /* 去除阴影 */
        background-color: inherit; /* 确保背景颜色保持不变 */
    }

    .download-button {
        margin-right: 6px!important; /* 调整按钮之间的间距 */
    }

    .dialog-icon {
        width: 30px; /* 根据需要调整图标的宽度 */
        height: 30px; /* 根据需要调整图标的高度 */
        margin-right: 8px; /* 根据需要调整图标和文字之间的间距 */
        vertical-align: middle;
    }

    .custom-dialog .el-dialog__title {
        color: #6595e8;
        text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 2px 2px 0 #aaa;
        font-weight: bold;
        font-size: 15px;
        z-index: 100000!important; /* 确保弹出框在最上层 */
    }
    .support-items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    top: 24px;
    position: relative;
}

        .el-avatar {
        display: inline-block;
        box-sizing: border-box;
        text-align: center;
        overflow: hidden;
        color: #fff;
        background: #C0C4CC;
        width: 50px !important;
        height: 50px !important;
        top: 19px !important;
        position: fixed;
        right: 17px;
    }
        .card {
        border: 0px solid #ccc;
        padding: 5px;
        margin: 12px;
        border-radius: 19px;
        overflow: hidden;
        top: 61px;
        position: relative;
        box-shadow: 0 10px 15px rgba(64, 158, 255, 0.7);
        background-color: #26202C;
        margin: 2px -1px;
        left: -1px;
    }

        .card-image {
        width: 169px;
        height: auto;
        border-radius: 19px 19px 0 0;
        position: relative;
        top: -77px;
        left: 78px;
    }

        .input-container {
        position: absolute;
        display: flex;
        align-items: center;
        top: 176px;
        z-index: 2;
        left: 39px;
        width: 307px;
    }

            .additional-text {
        text-align: center;
        font-size: 14px;
        transform: translateY(-228px);
        color: #555;
        margin-left: -322px;
        font-weight: bold;
        position: relative;
        top: 160px;
    }

        .tags-container {
        display: flex;
        gap: 6px;
        flex-wrap: wrap;
        top: 3px;
        left: 313px;
        position: fixed;
    }

    .custom-tag {
        border-radius: 20px!important; /* 圆角效果 */
        padding: 1px 10px!important; /* 内边距 */
        color: #5b6bff !important;
    }
    .el-tag {
    background-color: transparent !important;
    height: 32px !important;
    line-height: 30px !important;
    font-size: 12px !important;
    border-width: 1px !important;
    border-style: solid !important;
    box-sizing: border-box !important;
    white-space: nowrap !important;
    border: 1px dashed #09afe899 !important;
}

        .info-card {
    position: relative;
    width: 100px;
    padding: 20px;
    border: 0 solid #ccc;
    border-radius: 20px;
    background-color: #D8C9AE; /* 替换 background-image 为 background-color */
    /* 移除与背景图像相关的属性 */
    /* background-size: 150px 244px; */
    /* background-position: center; */
    /* background-repeat: no-repeat; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    top: 64px;
    left: 318px;
    height: 177px;
    
}

        .announcement-card {
        position: absolute;
        padding: 20px;
        border: 0px solid #ccc;
        border-radius: 20px;
        background-image: url(https://tc.qianying888.com/app/hide.php?key=V2tNTmJZYjk0SDFoMGdrWUtraDZKcENMNDE4cVFKZHc=);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
        top: 64px;
        left: 473px;
        right: 1px;
        color: white !important;
        height: 66px;
        width: auto;
    }

    .announcement-title {
        color: #fff;
        position: relative;
        top: -10px;
        left: -5px;
        font-weight: bold;
        font-size: 14px;
    }

    .announcement-card p {
        color: white !important;
        font-weight: bold !important;
        position: relative;
    }

    .announcement-card p.move-up {
        top: -15px; /* 向上移动 10px */
        font-size: 12px;
    }

    .announcement-card p.move-left {
        left: -3px; /* 向左移动 10px */
        top: -23px;
        font-size: 14px;
    }

    .announcement-card p.smaller-font {
        font-size: 14px; /* 调整字体大小 */
    }

    .el-loading-spinner {
        top: -154%!important;
        margin-top: -345px!important;
        width: 100%!important;
        text-align: center!important;
        position: absolute!important;
    }

        .tip-card {
        padding: 20px;
        border: 0px solid #ccc;
        border-radius: 20px;
        background-image: url(https://tc.qianying888.com/app/hide.php?key=RHE5K3c2cm55QmlHNTA5RlZ5THNIWkNMNDE4cVFKZHc=);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
        top: 174px;
        left: 472px;
        color: white !important;
        position: absolute;
        width: auto;
        height: 66px;
        right: 1px;
    }

    .tip-title {
        color: #fff;
        position: relative;
        top: -10px;
        left: -6px;
        font-weight: bold;
        font-size: 14px;
    }

    .tip-card p {
        color: white !important;
        font-weight: bold !important;
        position: relative;
    }

    .tip-card p.move-up {
        top: -15px; /* 向上移动 10px */
        font-size: 12px;
    }

    .tip-card p.move-left {
        left: -3px; /* 向左移动 10px */
        top: -23px;
        font-size: 14px;

}
        .ggtitle {
        text-align: center;
        font-size: 14px;
        transform: translateY(28px);
        color: #555;
        margin-left: 2px;
        font-weight: bold;
        position: fixed;
        top: -71px;
        width: auto;
        height: 3px;
        left: 317px;
  }
  
  .container {
  display: flex;
  justify-content: space-between;
  margin-top: 0px; /* 调整顶部间距 */
}
  
}

              
</style>